const GTM_ID = 'GTM-NSRQRLH';

export function initGTM() {
  try {
    const w = window as any;
    const d = document;
    const s = 'script';
    const l = 'dataLayer';
    const i = GTM_ID;
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode?.insertBefore(j, f);
    console.info('GTM initialized');
  } catch (err) {
    console.error('Cannot initialize GTM');
    console.error(err);
  }
}
