<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <path
      fill="currentColor"
      d="M11.083 8.166a2.899 2.899 0 0 0-2.227 1.057L5.706 7.8c.08-.26.123-.53.127-.801a2.875 2.875 0 0 0-.123-.793l3.147-1.43a2.9 2.9 0 1 0-.69-1.86c0 .082.017.16.024.242L4.725 4.732a2.886 2.886 0 0 0-1.808-.649 2.917 2.917 0 1 0 0 5.833 2.886 2.886 0 0 0 1.802-.641l3.472 1.567c-.007.081-.024.159-.024.241a2.916 2.916 0 1 0 2.916-2.917Zm0-6.416a1.167 1.167 0 1 1 0 2.333 1.167 1.167 0 0 1 0-2.333ZM2.917 8.166a1.167 1.167 0 1 1 0-2.333 1.167 1.167 0 0 1 0 2.333Zm8.166 4.084a1.166 1.166 0 1 1 0-2.333 1.166 1.166 0 0 1 0 2.333Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
