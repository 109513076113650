import { createPage } from '@/shared/lib/createPage';
import { IconPersonCircle } from '@/shared/ui/icons';

export const MyLeadsPage = createPage({
  name: 'MyLeads',
  path: '/my-leads',
  component: () => import('./my-leads.view.vue'),
  meta: {
    title: 'My leads',
    icon: IconPersonCircle,
  },
});
