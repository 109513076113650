import { createPage } from '@/shared/lib/createPage';
import { IconCompass } from '@/shared/ui/icons';

export const RootPage = createPage({
  name: 'Root',
  path: '/',
  component: () => import('./root.view.vue'),
  meta: {
    title: 'Contact Search',
    icon: IconCompass,
  },
});
