import { Theme } from '@/features/theme/theme.config';
import { createPage } from '@/shared/lib/createPage';

export const MailSentPage = createPage({
  name: 'MailSent',
  path: '/mail-sent',
  component: () => import('./mail-sent.view.vue'),
  meta: {
    theme: Theme.Auth,
    title: 'Mail sent',
  },
});
