export const initBeamer = () => {
  const script1 = document.createElement('script');
  const script2 = document.createElement('script');

  script1.src = 'https://app.getbeamer.com/js/beamer-embed.js';

  script1.defer = true;

  script2.textContent = `
    var beamer_config = {
      product_id: 'NhSwmmEV45761',
      selector : ".notifications",
    };
  `;

  document.getElementsByTagName('body')[0].appendChild(script2);
  document.getElementsByTagName('body')[0].appendChild(script1);
};
