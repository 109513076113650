import { Billing } from './billing.config';

import { Store } from '@/shared/lib/store';
import { BillingApi } from '@/entities/billing/api/billing.api';
import { LOGROCKET } from '@/shared/lib/anal/logrocket';
import { userStore } from '@/entities/user';

interface StoreState {
  loadingState: Billing.LoadingState;
  plan?: Billing.Subscription.Plan;
  duration?: Billing.Subscription.Duration;
  credits: number;
  nextBillingDate?: string;
  price?: number;
  trial?: { started: boolean; ended: boolean; credits: number };
}

const updateApi = BillingApi.Subscription.check();

class BillingStore extends Store<StoreState> {
  protected data() {
    return {
      loadingState: Billing.LoadingState.loading,
      plan: undefined,
      duration: undefined,
      credits: 0,
      price: undefined,
      nextBillingDate: undefined,
    };
  }

  async update() {
    this.state.loadingState = Billing.LoadingState.loading;
    const result = await updateApi.run();
    if (result.ok) {
      this.state.credits = result.data.credits || 0;
      this.state.trial = result.data.customer?.data?.trial;
      this.state.plan = result.data.subscription?.plan;
      this.state.duration = result.data.subscription?.duration;
      this.state.price = result.data.subscription?.price;
      this.state.nextBillingDate = result.data.subscription?.nextBillingDate;
      this.state.loadingState = Billing.LoadingState.finished;
      const userState = userStore.getState();
      if (userState.user) {
        LOGROCKET.SET_USER({
          userId: userState.user.uid,
          name: `${userState.user.customClaims.firstName} ${userState.user.customClaims.lastName}`,
          email: userState.user.email,
          subscription: `${this.state.credits}:${this.state.plan}:${this.state.duration}`,
        });
      }
    } else if (result.err?.status !== 401) {
      await new Promise((r) => setTimeout(r, 1000));
      await this.update();
      // this.state.loadingState = Billing.LoadingState.error;
    }
  }

  isCreditsEnough(amount: number) {
    return this.state.credits >= amount;
  }
}

export const billingStore = new BillingStore();
