import { createPage } from '@/shared/lib/createPage';

export const AccountPage = createPage({
  name: 'Account',
  path: '',
  component: () => import('./account.view.vue'),
  meta: {
    title: 'My account',
  },
});
