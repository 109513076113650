import { Ref, ref } from 'vue';

/**
 * Operation for promises
 * ```
 * const op = new Operation((a: string) => new Promise(r(a)));
 * const result = await op.run('arg')
 * if (result.ok) {
 * /// result.data
 * } else {
 * /// result.err
 * }
 * ```
 */
export class Operation<Data, Args extends unknown[], Err = any> {
  private start: () => symbol;
  private end: (id: symbol) => void;
  isLoading: Ref<boolean>;
  private id = Symbol();

  constructor(private op: (...args: Args) => Promise<Data>) {
    this.isLoading = ref(false);
    this.start = () => {
      this.isLoading.value = true;
      this.id = Symbol();
      return this.id;
    };
    this.end = (id: symbol) => {
      if (id === this.id) {
        this.isLoading.value = false;
      }
    };
  }

  async run(
    ...args: Args
  ): Promise<
    | { ok: true; data: Data; err: null; isLastRun: boolean }
    | { ok: false; data: null; err: Err; isLastRun: boolean }
  > {
    const id = this.start();
    try {
      const data = await this.op(...args);
      return { ok: true, data, err: null, isLastRun: this.id === id };
    } catch (err: any) {
      return { ok: false, data: null, err, isLastRun: this.id === id };
    } finally {
      this.end(id);
    }
  }
}
