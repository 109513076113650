<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 15">
    <path
      fill="currentColor"
      d="M10.792 3.417H9.319A3.212 3.212 0 0 0 6.125.5H3.208A3.212 3.212 0 0 0 0 3.708v7.584A3.212 3.212 0 0 0 3.208 14.5h7.584A3.212 3.212 0 0 0 14 11.292V6.625a3.212 3.212 0 0 0-3.208-3.208ZM1.75 11.292V3.708A1.458 1.458 0 0 1 3.208 2.25h2.917a1.458 1.458 0 0 1 1.458 1.458v9.042H3.208a1.458 1.458 0 0 1-1.458-1.458Zm10.5 0a1.458 1.458 0 0 1-1.458 1.458H9.333V5.167h1.459a1.458 1.458 0 0 1 1.458 1.458v4.667Zm-.583-4.084a.875.875 0 1 1-1.751 0 .875.875 0 0 1 1.75 0Zm0 2.917a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0ZM4.083 4.292a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0Zm0 2.916a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0ZM7 4.292a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0Zm0 2.916a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0Zm-2.917 2.917a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0Zm2.917 0a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
