import { Theme } from '@/features/theme/theme.config';
import { createPage } from '@/shared/lib/createPage';

export const ChangePasswordPage = createPage({
  name: 'Change',
  path: '/change-password',
  component: () => import('./change-password.view.vue'),
  meta: {
    theme: Theme.Auth,
    title: 'Reset password',
  },
});
