<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 9">
    <path
      fill="currentColor"
      d="M9.5.7 4.5 6l-2-2.3a1 1 0 0 0-1.5 0c-.3.5-.3 1.1 0 1.6l2.9 3c.2.2.4.3.7.3.3 0 .5-.1.7-.3l5.7-6c.3-.5.3-1.1 0-1.6a1 1 0 0 0-1.5 0Z"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
