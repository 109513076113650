<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7 21.2a12 12 0 10-15.5 0l.4-.2L8.3 19c.5-.2.8-.7.8-1.2v-1.4s-1-1.2-1.5-2.8c-.3-.2-.6-.6-.6-1v-1.5c0-.3.2-.6.4-.8V8.2S7 4.8 12 4.8s4.6 3.4 4.6 3.4v2.2c.3.2.4.5.4.8v1.5c0 .6-.4 1-.9 1.1a8 8 0 01-1.6 2.7V18c0 .6.3 1 .9 1.3l4 1.8.3.2zM12 24z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
