import { Theme } from '@/features/theme/theme.config';
import { AccountPage } from '@/pages/profile/account/account.child';
import { BillingPage } from '@/pages/profile/billing/billing.child';
import {
  ExportsCompanyPage,
  ExportsLeadPage,
} from '@/pages/profile/exports/exports.child';
import { createPage } from '@/shared/lib/createPage';

export const ProfilePage = createPage({
  name: 'Profile',
  path: '/profile',
  component: () => import('./profile.view.vue'),
  meta: {
    title: 'Profile',
    theme: Theme.Profile,
  },
  children: [AccountPage, ExportsLeadPage, ExportsCompanyPage, BillingPage],
});
