<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0a10 10 0 100 20 10 10 0 000-20zm0 8.9c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1zM4 16l8.2-3.8L16 4 7.8 7.8 4 16z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
