<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      fill="currentColor"
      d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z"
    />
    <path
      fill="#fff"
      d="M15.975 10.805v4.123h-2.39V11.08c0-.966-.345-1.625-1.21-1.625-.661 0-1.054.444-1.227.874-.063.153-.08.367-.08.582v4.015h-2.39s.033-6.514 0-7.189h2.391v1.02c-.005.007-.012.015-.016.023h.016v-.024c.318-.489.884-1.188 2.154-1.188 1.573 0 2.752 1.028 2.752 3.236ZM6.178 4.273c-.818 0-1.353.537-1.353 1.242 0 .69.52 1.242 1.321 1.242h.016c.834 0 1.352-.552 1.352-1.242-.016-.705-.518-1.242-1.336-1.242Zm-1.21 10.655h2.389v-7.19h-2.39v7.19Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
