import { Theme } from '@/features/theme/theme.config';
import { LoginPage } from '@/pages/login/login.page';
import { VerifyPage } from '@/pages/verify/verify.page';
import { createPage } from '@/shared/lib/createPage';
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ChangePasswordPage } from './change-password/change-password.page';

export const ActionPage = createPage({
  name: 'Action',
  path: '/action',
  component: defineComponent({
    setup() {
      const route = useRoute();
      const router = useRouter();
      switch (route.query.mode) {
        case 'resetPassword':
          router.replace({ name: ChangePasswordPage.name, query: route.query });
          break;
        case 'verifyEmail':
          router.replace({ name: VerifyPage.name, query: route.query });
          console.log('verifyEmail');
          break;
        default:
          console.log('login');
          router.replace({ name: LoginPage.name });
      }
      return () => <div></div>;
    },
  }),
  meta: {
    title: 'action',
    theme: Theme.Auth,
  },
});
