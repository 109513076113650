export function formatDigits(n: number | string): string {
  const [d = '', f = ''] = String(n).split(',');
  const dFormatted = d
    .split('')
    .flatMap((digit, i) => {
      const index = d.length - 1 - i;
      return index % 3 === 0 && index !== 0 ? [digit, ','] : [digit];
    })
    .join('')
    .trim();

  const fFormatted = f ? `.${f}` : f;
  return `${dFormatted}${fFormatted}`;
}
