import { Theme } from './theme.config';
import { ThemeLogin } from './ui/login';
import { ThemeMain } from './ui/main';
import { ThemeProfile } from './ui/profile';

export const ThemeComponents = {
  [Theme.Main]: ThemeMain,
  [Theme.Auth]: ThemeLogin,
  [Theme.Profile]: ThemeProfile,
};
