import { User } from './user.config';

import { Store } from '@/shared/lib/store';
import { Auth } from '@/features/auth';
import { Ref, ref } from 'vue';
import { LOGROCKET } from '@/shared/lib/anal/logrocket';

interface StoreState {
  status: Auth.State;
  user: Ref<null | User.Entity>;
}

class UserStore extends Store<StoreState> {
  protected data() {
    return {
      status: Auth.State.loading,
      user: ref(null),
    };
  }

  setUser(user: User.Entity) {
    this.state.user = user;
    this.state.status = Auth.State.authorized;

    LOGROCKET.SET_USER({
      userId: user.uid,
      name: `${user.customClaims.firstName} ${user.customClaims.lastName}`,
      email: user.email,
      subscription: 'none',
    });
  }

  invalidateUser() {
    this.state.user = null;
    this.state.status = Auth.State.unauthorized;
    localStorage.removeItem('session');
  }
}

export const userStore = new UserStore();
