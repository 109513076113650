import { Theme } from '@/features/theme/theme.config';
import { RouteRecordRaw } from 'vue-router';

interface RouteExtended {
  meta: {
    title: string;
    theme?: Theme;
    icon?: any;
  };
}

export function createPage<T extends RouteRecordRaw, U extends RouteExtended>(
  page: T & U,
): T & U {
  return page;
}
