import type { FormContext } from 'vee-validate';

export namespace Auth {
  export enum State {
    loading,
    authorized,
    unauthorized,
  }

  export interface SignInResponse {
    session: string;
  }

  export interface SignInDto {
    email: string;
    password: string;
  }

  export type FormInterface = FormContext<SignInDto>;
}
