<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 11">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M6.3 2.7c0 1.162-.933 2.1-2.096 2.1a2.099 2.099 0 1 1 0-4.2C5.367.6 6.3 1.538 6.3 2.7Zm5.6 0c0 1.162-.933 2.1-2.096 2.1a2.099 2.099 0 1 1 0-4.2c1.163 0 2.096.938 2.096 2.1ZM4.455 6.2C2.972 6.2 0 7.02 0 8.65V9.7c0 .385.286.7.636.7h7.637c.35 0 .636-.315.636-.7V8.65c0-1.63-2.972-2.45-4.454-2.45Zm4.473.035c.223-.02.433-.035.618-.035C11.028 6.2 14 7.02 14 8.65V9.7c0 .385-.286.7-.636.7h-3.296c.07-.217.114-.455.114-.7V8.65c0-1.029-.503-1.806-1.228-2.387a.083.083 0 0 1-.006-.008c-.005-.007-.01-.015-.02-.02Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
