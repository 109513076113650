export namespace User {
  export interface Entity {
    uid: string;
    displayName: string;
    email: string;
    emailVerified: boolean;
    disabled: boolean;
    customClaims: Claims;
  }

  export interface Claims {
    role: Role;
    firstName: string;
    lastName: string;
    jobTitle: string;
    companyName: string;
    filled: boolean;
    finishedOnboarding: boolean;
  }

  export enum Role {
    user = 'user',
    admin = 'admin',
  }
}
