<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="m17 8.3-6.8 5.2a2 2 0 0 1-2.4 0L1 8.3a1 1 0 0 1 0-1.6l6.8-5.3a2 2 0 0 1 2.4 0L17 6.7a1 1 0 0 1 0 1.6Zm-8.6 8.2c.3.3.8.3 1.2 0l6.2-4.8a1 1 0 0 1 1.2 0 1 1 0 0 1 0 1.6l-6.8 5.3a2 2 0 0 1-2.4 0L1 13.3a1 1 0 0 1 0-1.5 1 1 0 0 1 1.2 0l6.2 4.7Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
