<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7 6h-1V4.7a4.7 4.7 0 00-9.4 0V6h-1c-.2 0-.3.1-.3.3v8.4c0 .7.6 1.3 1.3 1.3h9.4c.7 0 1.3-.6 1.3-1.3V6.3c0-.2-.1-.3-.3-.3zM9 13a.3.3 0 01-.3.3H7.3A.3.3 0 017 13l.2-2c-.3-.2-.5-.6-.5-1a1.3 1.3 0 112 1l.3 2zm1.7-7H5.3V4.7a2.7 2.7 0 015.4 0V6z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
