export namespace Billing {
  export enum LoadingState {
    loading,
    finished,
    error,
  }
  export namespace Subscription {
    export enum Plan {
      starter = 'starter',
      booster = 'booster',
    }
    export enum Duration {
      yearly = 'yearly',
      monthly = 'monthly',
    }
  }
  export namespace Order {
    export enum State {
      waitingforpayment = 'waitingforpayment',
      processing = 'processing',
      completed = 'completed',
      error = 'error',
    }
  }
}
