<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.915 1.06082C9.62405 1.29924 8.45754 2.05251 7.64192 3.17446C7.17296 3.81957 6.86121 4.56403 6.72397 5.36661C6.64203 5.8459 6.6438 6.7204 6.72781 7.23082C6.91053 8.3416 7.40858 9.28123 8.2596 10.1207C8.69413 10.5493 9.0809 10.8318 9.57448 11.0808C10.0234 11.3073 10.4101 11.4328 10.9541 11.5285C11.7538 11.6691 12.5429 11.6261 13.363 11.3973C13.8059 11.2737 14.5403 10.8919 14.9694 10.5621C15.6533 10.0366 16.2477 9.29206 16.6056 8.51257C17.1259 7.37965 17.2154 5.91 16.8384 4.69252C16.5875 3.88272 16.1747 3.19733 15.5533 2.55911C14.7533 1.73731 13.8183 1.2336 12.7718 1.06048C12.2834 0.979698 11.3533 0.97987 10.915 1.06082ZM6.95352 11.6792C6.52131 11.759 6.02411 11.944 5.6238 12.1739C5.00896 12.5271 4.33518 13.3062 3.92678 14.1364C3.48905 15.0262 3.15969 16.3792 3.03244 17.8102C3.005 18.119 2.99281 18.7235 3.00432 19.2073C3.02245 19.9707 3.03627 20.0999 3.13625 20.4387C3.19771 20.647 3.29744 20.9179 3.35788 21.0406C3.6343 21.6021 4.12881 22.1473 4.6433 22.4576C4.791 22.5468 5.05777 22.6771 5.2361 22.7474C5.91544 23.015 5.72429 23.0082 12.1651 22.995L18.0207 22.9829L18.3579 22.8858C18.7624 22.7692 19.2125 22.5572 19.5466 22.3258C19.6815 22.2324 19.9179 22.0197 20.072 21.8531C20.5129 21.3764 20.8045 20.7788 20.9356 20.0835C20.9865 19.8131 21.0034 19.4977 20.9994 18.8878C20.9873 17.0244 20.6486 15.3308 20.0428 14.1038C19.802 13.6162 19.5274 13.2148 19.1801 12.843C18.8495 12.4892 18.5589 12.2653 18.1682 12.0638C17.6511 11.797 16.9125 11.6126 16.3607 11.6127C16.1411 11.6127 16.0273 11.6389 15.8262 11.736C15.6857 11.8037 15.2969 12.04 14.9622 12.261C14.6274 12.482 14.1861 12.7365 13.9814 12.8267C13.5134 13.0329 13.0134 13.1815 12.5614 13.2489C12.1207 13.3147 11.434 13.2781 10.9806 13.1648C10.429 13.0269 9.78877 12.75 9.31661 12.445C8.33745 11.8126 8.20358 11.7316 8.04151 11.6733C7.83379 11.5986 7.3768 11.601 6.95352 11.6792Z"
      fill="#B0B0B0"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
