<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 15">
    <path
      fill="currentColor"
      d="M13.012 5.066 9.194 1.408a3.103 3.103 0 0 0-4.375-.012l-3.83 3.67A3.224 3.224 0 0 0 0 7.384v3.908A3.212 3.212 0 0 0 3.208 14.5h7.584A3.212 3.212 0 0 0 14 11.292V7.384a3.225 3.225 0 0 0-.988-2.318Zm-6.969-2.42a1.359 1.359 0 0 1 1.925.013l3.14 3.008L8.03 8.823a1.493 1.493 0 0 1-2.062 0L2.85 5.705l3.192-3.058Zm6.207 8.646a1.458 1.458 0 0 1-1.458 1.458H3.208a1.458 1.458 0 0 1-1.458-1.458V7.384c0-.093.01-.186.029-.276L4.73 10.06a3.222 3.222 0 0 0 4.545-.008l2.93-3.004c.028.11.043.222.044.335v3.908Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: flex;
}
</style>
