import { Theme } from '@/features/theme/theme.config';
import { createPage } from '@/shared/lib/createPage';

export const SignupPage = createPage({
  name: 'SignUp',
  path: '/signup',
  component: () => import('./signup.view.vue'),
  meta: {
    theme: Theme.Auth,
    title: 'Sign up',
  },
});
