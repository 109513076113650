<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="m14.667 5.59-.33-.278a3 3 0 0 0-1.932-.705H8.446A2.946 2.946 0 0 0 5.5 7.554v0A2.946 2.946 0 0 0 8.446 10.5h4.108a2.946 2.946 0 0 1 2.946 2.947v0a2.946 2.946 0 0 1-2.946 2.946H7.727a3 3 0 0 1-2.102-.86l-.125-.122M10.5 18V3"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
