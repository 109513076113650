import { Billing } from '@/entities/billing/billing.config';
import { billingStore } from '@/entities/billing/billing.state';
import { Operation } from '@/shared/lib/Operation';
import { request } from '@/shared/lib/request';

export namespace BillingApi {
  export namespace Subscription {
    export function subscribe() {
      return new Operation(
        async (props: {
          plan: Billing.Subscription.Plan;
          duration: Billing.Subscription.Duration;
          trial?: boolean;
        }) => {
          interface Response {
            redirectUrl: string;
          }
          const { data } = await request.post<Response>('/billing/subscribe', {
            returnUrl: location.href,
            ...props,
          });
          return data;
        },
      );
    }

    export function check() {
      return new Operation(async () => {
        interface Response {
          credits?: number;
          customer?: {
            data?: {
              trial?: {
                started: boolean;
                ended: boolean;
                credits: number;
              };
              credits?: number;
              email: string;
              id: string;
              metadata: {
                firebaseUID: string;
              };
            };
          };
          subscription?: {
            id: string;
            plan: Billing.Subscription.Plan;
            duration: Billing.Subscription.Duration;
            price: number;
            /** ISO date string */
            nextBillingDate: string;
          };
        }
        const { data } = await request.post<Response>(
          '/billing/subscribe-status',
        );
        return data;
      });
    }

    export function checkPlan() {
      const billingState = billingStore.getState();
      async function stateCheck(
        props: { plan: Billing.Subscription.Plan },
        attempt = 0,
      ): Promise<boolean> {
        await billingStore.update();

        if (billingState.plan === props.plan) {
          return true;
        }
        if (attempt > 30) {
          throw new Error('Too many attempts');
        }
        await new Promise((r) => setTimeout(r, 1000));
        return await stateCheck(props, attempt + 1);
      }
      return new Operation(stateCheck);
    }
  }

  export namespace Credits {
    export function buy() {
      return new Operation(async (props: { quantity: number }) => {
        interface Response {
          redirectUrl: string;
          orderId: string;
        }

        const { data } = await request.post<Response>(
          '/billing/purchase-credits',
          {
            quantity: props.quantity,
            successUrl: location.href,
            cancelUrl: location.href,
          },
        );
        return data;
      });
    }
    export function buyState() {
      interface Response {
        state: Billing.Order.State;
      }
      async function stateCheck(
        props: {
          orderId: string;
        },
        attempt = 0,
      ): Promise<Response> {
        const { data } = await request.post<Response>(
          '/billing/purchase-status',
          props,
        );
        const finishedProcessing = [
          Billing.Order.State.error,
          Billing.Order.State.completed,
        ].includes(data.state);
        if (finishedProcessing) {
          billingStore.update();
          return data;
        }
        if (attempt >= 120) {
          throw new Error('Too many attempts');
        }
        await new Promise((r) => setTimeout(r, 1000));
        return await stateCheck(props, attempt + 1);
      }
      return new Operation(stateCheck);
    }
  }
}
