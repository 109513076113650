<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.57 2.86v14.28m0 0L11 11.8m3.57 5.35 3.57-5.35"
    />
    <path
      fill="currentColor"
      d="M3.83 17.29H2.32l1.96-5.82h1.87l1.96 5.82h-1.5l-1.37-4.35h-.05l-1.36 4.35ZM3.63 15h3.15v1.07H3.63V15ZM2.91 8v-.8l2.83-3.88H2.9V2.18h4.6v.8L4.7 6.86h2.82V8h-4.6Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
