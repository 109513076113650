import { Theme } from '@/features/theme/theme.config';
import { createPage } from '@/shared/lib/createPage';

export const FillDataPage = createPage({
  name: 'FillData',
  path: '/fill-data',
  component: () => import('./fill-data.view.vue'),
  meta: {
    theme: Theme.Auth,
    title: 'FillData',
  },
});
