import { RootPage } from '@/pages/root.page';

import { MyLeadsPage } from '@/pages/my-leads/my-leads.page';
import { CompaniesPage } from '@/pages/companies/companies.page';

export const navigation = [
  {
    to: RootPage.path,
    icon: RootPage.meta.icon,
    title: 'Explore',
    isActive: (name: string) =>
      [RootPage.name, CompaniesPage.name].includes(name),
  },
  {
    to: MyLeadsPage.path,
    icon: MyLeadsPage.meta.icon,
    title: 'My leads',
    isActive: (name: string) => [MyLeadsPage.name].includes(name),
  },
];
