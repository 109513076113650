<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17">
    <path
      fill="currentColor"
      d="M4.3 7.75a.75.75 0 0 0 0 1.5v-1.5Zm7.4 1.5a.75.75 0 0 0 0-1.5v1.5ZM8.75 4.8a.75.75 0 1 0-1.5 0h1.5Zm-1.5 7.4a.75.75 0 0 0 1.5 0h-1.5ZM4.3 9.25H8v-1.5H4.3v1.5Zm3.7 0h3.7v-1.5H8v1.5ZM7.25 4.8v3.7h1.5V4.8h-1.5Zm0 3.7v3.7h1.5V8.5h-1.5Z"
    />
    <circle
      cx="8"
      cy="8.5"
      r="6.67"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
