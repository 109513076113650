<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.57 17.14V2.86m0 0 3.57 5.35m-3.57-5.35L11 8.2"
    />
    <path
      fill="currentColor"
      d="M3.83 8H2.32l1.96-5.82h1.87L8.11 8h-1.5L5.23 3.65h-.05L3.83 8Zm-.2-2.29h3.15v1.07H3.63V5.7ZM2.91 17.29v-.8l2.83-3.88H2.9v-1.14h4.6v.8L4.7 16.15h2.82v1.14h-4.6Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
