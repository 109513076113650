import { Theme } from '@/features/theme/theme.config';
import { createPage } from '@/shared/lib/createPage';

export const VerifyPage = createPage({
  name: 'Verify',
  path: '/verify',
  component: () => import('./verify.view.vue'),
  meta: {
    theme: Theme.Auth,
    title: 'Verify E-mail',
  },
});
