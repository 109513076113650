<template>
  <div class="theme-login">
    <div class="inner"><slot /></div>
  </div>
</template>

<script lang="ts" setup></script>
<style lang="scss" scoped>
.theme-login {
  background-color: #fafafa;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &::before,
  &::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
  }

  $left: 0% + calc(800 / 1920 * 100);
  $right: 100% - $left;

  &::before {
    width: $left;
    background-color: red;
    background-color: #ffffff;
    left: 0;
  }

  &::after {
    right: 0;
    width: $right;
    background-color: #fafafa;
  }
}
.inner {
  $left: 0% + calc(800 / 1920 * 100);
  $right: 100% - $left;

  display: grid;
  grid-template-columns: $left $right;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
</style>
