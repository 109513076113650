<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 13">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M6 .7a5.8 5.8 0 1 0 0 11.6A5.8 5.8 0 0 0 6 .7Zm-.6 2.9v1.1h1.2V3.6H5.4Zm1.2 5.2c0 .4-.3.6-.6.6a.6.6 0 0 1-.6-.6V6.5c0-.3.3-.6.6-.6s.6.3.6.6v2.3ZM1.3 6.5a4.7 4.7 0 1 0 9.4 0 4.7 4.7 0 0 0-9.4 0Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
