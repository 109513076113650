import { createPage } from '@/shared/lib/createPage';
import { IconCompany } from '@/shared/ui/icons';

export const CompaniesPage = createPage({
  name: 'Companies',
  path: '/companies',
  component: () => import('./companies.view.vue'),
  meta: {
    title: 'Company Search',
    icon: IconCompany,
  },
});
