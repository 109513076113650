import LogRocket from 'logrocket';

const isDevelopment = process.env.NODE_ENV === 'development';

export namespace LOGROCKET {
  export function INIT(appId: string) {
    if (!isDevelopment) LogRocket.init(appId, {});
  }

  export function SET_USER(options: {
    userId: string;
    name: string;
    email: string;
    subscription: string;
  }) {
    if (!isDevelopment) {
      LogRocket.identify(options.userId, {
        name: options.name,
        email: options.email,
        subscriptionType: options.subscription,
      });
    }
  }
}
