<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 15">
    <path
      fill="currentColor"
      d="M9.667 5.167v-.401a3.103 3.103 0 0 0-3.1-3.1h-.692V.5h-1.75v1.167h-.692a3.1 3.1 0 0 0-.9 6.066l1.592.436v3.414h-.692a1.35 1.35 0 0 1-1.35-1.349v-.4H.333v.4a3.103 3.103 0 0 0 3.1 3.1h.692V14.5h1.75v-1.167h.692a3.1 3.1 0 0 0 .9-6.066l-1.592-.434V3.417h.692a1.351 1.351 0 0 1 1.35 1.349v.4h1.75Zm-2.694 3.78a1.35 1.35 0 0 1-.408 2.636h-.69V8.646l1.098.3ZM4.125 6.353l-1.098-.3a1.35 1.35 0 0 1 .406-2.637h.692v2.937Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
