import { DeepReadonly, reactive } from 'vue';
import type { UnwrapNestedRefs } from 'vue';

export abstract class Store<T> {
  protected state: UnwrapNestedRefs<T>;
  protected abstract data(): T;

  constructor() {
    this.state = reactive(this.data() as RR) as UnwrapNestedRefs<T>;
  }

  public getState() {
    return this.state as DeepReadonly<UnwrapNestedRefs<T>>;
  }
}

type RR = Record<string, unknown>;
