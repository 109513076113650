import { Operation } from '@/shared/lib/Operation';
import { request, setAuthInterceptor } from '@/shared/lib/request';
import { auth } from '@/shared/lib/firebase';
import { initHubspot } from '@/shared/lib/anal/hubspot';
import {
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth';
import { Auth } from '../auth.config';
import axios from 'axios';
import { User, userStore } from '@/entities/user';

export namespace AuthApi {
  export function sendResetEmail() {
    return new Operation(async (email: string) => {
      await sendPasswordResetEmail(auth, email);
    });
  }

  export function signUp() {
    return new Operation((form: { email: string; password: string }) => {
      return request.post<User.Entity>('/auth/signup', form);
    });
  }
  export function signIn(opts?: { sendEmailVerification?: boolean }) {
    const verifyApi = verify();
    return new Operation(async (form: { email: string; password: string }) => {
      try {
        const { user } = await signInWithEmailAndPassword(
          auth,
          form.email,
          form.password,
        );
        if (opts?.sendEmailVerification && !user.emailVerified) {
          await sendEmailVerification(user).catch((err) => {
            console.log(err);
          });
        }

        const idToken = await user.getIdToken();
        const { data } = await request.post<Auth.SignInResponse>(
          '/auth/signin',
          { idToken },
        );
        setAuthInterceptor(data.session);
        localStorage.setItem('session', data.session);
        await verifyApi.run();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          throw e;
        } else {
          throw toAxiosError('Invalid email or password');
        }
      }
    });
  }

  export function verify() {
    return new Operation(async () => {
      const session = localStorage.getItem('session');
      if (!session) {
        userStore.invalidateUser();
        throw toAxiosError('Not authenticated');
      }

      const { data } = await request.post<User.Entity>('/auth/verify', {
        session,
      });
      userStore.setUser(data);
      setAuthInterceptor(session);
      if (
        process.env.NODE_ENV === 'production' &&
        process.env.VUE_APP_IS_REVIEW !== 'true'
      ) {
        initHubspot();
      }

      return data;
    });
  }

  export function modify() {
    return new Operation(
      async (
        claims: Pick<
          User.Claims,
          'firstName' | 'lastName' | 'jobTitle' | 'companyName'
        >,
      ) => {
        interface Response {
          newUser: User.Entity;
        }
        const { data } = await request.post<Response>(`/auth/modify`, {
          modifyDto: claims,
        });
        userStore.setUser(data.newUser);
        return data;
      },
    );
  }

  export function changePSWD() {
    const userState = userStore.getState();
    return new Operation(async (pwd: { current: string; next: string }) => {
      const userCred = await signInWithEmailAndPassword(
        auth,
        userState.user?.email!,
        pwd.current,
      );
      await updatePassword(userCred.user, pwd.next);
    });
  }
}

function toAxiosError(err: any) {
  return {
    status: 403,
    data: { message: String(err) },
    message: 'Auth error',
  };
}
