<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M9 .7a8.3 8.3 0 1 0 0 16.6A8.3 8.3 0 0 0 9 .7Zm-.8 15a6.7 6.7 0 0 1-5.7-8.2l4 4v.8c0 1 .7 1.7 1.7 1.7v1.6Zm4.1-3.4c.8 0 1.4.5 1.6 1.2a6.6 6.6 0 0 0-2.4-10.7v.4c0 .9-.8 1.6-1.7 1.6H8.2v1.7c0 .5-.4.8-.9.8H5.7V9h5c.4 0 .8.4.8.8v2.5h.8Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
