import { createRouter, createWebHistory, Router } from 'vue-router';
import { routes } from './pages';

export let GLOBAL_ROUTER: Router;

export function initRouter() {
  GLOBAL_ROUTER = createRouter({
    history: createWebHistory(),
    routes: routes,
    end: false,
    strict: true,
  });
  return GLOBAL_ROUTER;
}
