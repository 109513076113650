import { createPage } from '@/shared/lib/createPage';

export const ExportsLeadPage = createPage({
  name: 'Lead exports',
  path: 'exports-leads',
  component: () => import('./exports-lead.view.vue'),
  meta: { title: 'Lead exports' },
});

export const ExportsCompanyPage = createPage({
  name: 'Company exports',
  path: 'exports-companies',
  component: () => import('./exports-company.view.vue'),
  meta: { title: 'Company exports' },
});
