import { createApp } from 'vue';
import App from '@/app/app.vue';
import { initRouter } from './app/router/router';
import { clickOutside } from './shared/lib/click-outside';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { initGTM } from '@/shared/lib/anal/gtm';
import { LOGROCKET } from '@/shared/lib/anal/logrocket';

async function init() {
  const router = initRouter();
  const app = createApp(App).use(clickOutside).use(router).use(Toast, {
    position: POSITION.TOP_CENTER,
    timeout: 4000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.4,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    transition: 'Vue-Toastification__fade',
    maxToasts: 5,
    newestOnTop: true,
  });
  await router.isReady();
  app.mount('#app');
}
init();
if (new URLSearchParams(location.search).has('close')) {
  window.close();
}

if (
  process.env.NODE_ENV === 'production' &&
  process.env.VUE_APP_IS_REVIEW !== 'true'
) {
  const initTrackers = () => {
    LOGROCKET.INIT('vqxll4/leadforce');
    initGTM();
  };
  setTimeout(initTrackers, 1000);
}
