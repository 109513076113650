import { initializeApp } from 'firebase/app';
import { getAuth, inMemoryPersistence, Auth } from 'firebase/auth';

const IS_DEV = process.env.NODE_ENV === 'development';
const IS_REVIEW = process.env.VUE_APP_IS_REVIEW === 'true';

const TEST_CFG = {
  apiKey: '.',
  projectId: 'demo',
};
const PROD_CFG = {
  apiKey: 'AIzaSyDntB6tq4b5Xnw4YvGxu1ZnZ7DNJqQQX0w',
  authDomain: 'leadsforce-io.firebaseapp.com',
  projectId: 'leadsforce-io',
  storageBucket: 'leadsforce-io.appspot.com',
  messagingSenderId: '57071192614',
  appId: '1:57071192614:web:e2d95345889e85d248f4e7',
  measurementId: 'G-WTK1G2K8BT',
};
const DEV_CFG = {
  apiKey: 'AIzaSyBcRhsO3gknqAvzyRTXy2w6oGoaFr9HzXo',
  authDomain: 'leadsforce-io-dev.firebaseapp.com',
  projectId: 'leadsforce-io-dev',
  storageBucket: 'leadsforce-io-dev.appspot.com',
  messagingSenderId: '86734561747',
  appId: '1:86734561747:web:10690e7d0970bb1eea4f26',
  measurementId: 'G-NDBSJMSHT2',
};
const app = initializeApp(IS_DEV ? TEST_CFG : IS_REVIEW ? DEV_CFG : PROD_CFG);

export const auth = getAuth(app);

if (IS_DEV) {
  connectEmulator(auth);
}

auth.setPersistence(inMemoryPersistence);

async function connectEmulator(appAuth: Auth) {
  const { connectAuthEmulator } = await import('firebase/auth');
  connectAuthEmulator(appAuth, 'http://localhost:7777', {
    disableWarnings: true,
  });
}
