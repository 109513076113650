<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.59277 6.12505L8.18587 10.3723C9.25832 11.1867 10.742 11.1867 11.8145 10.3723L17.4076 6.125"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.66699 6.35719C1.66699 5.63465 2.05672 4.96828 2.68647 4.61404L9.0198 1.05154C9.62863 0.709079 10.372 0.709079 10.9808 1.05154L17.3142 4.61404C17.9439 4.96828 18.3337 5.63465 18.3337 6.3572V13.5C18.3337 14.6046 17.4382 15.5 16.3337 15.5H3.66699C2.56242 15.5 1.66699 14.6046 1.66699 13.5V6.35719Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
