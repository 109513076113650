<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 13">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M3.637.291A.75.75 0 0 1 4.23 0h7.54a.75.75 0 0 1 .593.291l3.48 4.5a.75.75 0 0 1-.072.999l-7.25 7a.75.75 0 0 1-1.042 0l-7.25-7a.75.75 0 0 1-.072-.999l3.48-4.5Zm.96 1.209L1.755 5.177 8 11.207l6.246-6.03L11.402 1.5H4.598Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
