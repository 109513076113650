<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 0a12 12 0 100 24 12 12 0 000-24zm0 21A9 9 0 014.7 6.8l12.5 12.5A8.9 8.9 0 0112 21zm7.3-3.8L6.8 4.7C8.3 3.6 10 3 12 3a9 9 0 017.3 14.2z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
