<template>
  <svg viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 .4v3.2c0 .2-.2.4-.4.4H.4C.2 4 0 3.8 0 3.6V.4C0 .2.2 0 .4 0h17.2c.2 0 .4.2.4.4z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
