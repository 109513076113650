<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M2.827 4.064c.038.035 2.101 1.756 4.586 3.824 2.484 2.069 4.548 3.76 4.587 3.76.039 0 2.103-1.691 4.587-3.76a1742 1742 0 0 0 4.586-3.824C21.23 4.01 19.818 4 12 4s-9.23.01-9.173.064ZM1 11.959c0 6.417.004 6.792.08 6.731.062-.049 7.427-6.667 7.73-6.945.05-.046-.67-.666-3.816-3.283A687.936 687.936 0 0 0 1.06 5.2C1.012 5.172 1 6.536 1 11.96Zm18.052-3.544c-2.133 1.78-3.872 3.258-3.865 3.284.007.026 1.72 1.582 3.808 3.457l3.9 3.507.105.096v-6.794c0-3.737-.016-6.793-.035-6.791-.02.002-1.78 1.46-3.913 3.24ZM6.509 16.487l-3.876 3.496 4.684.012c2.575.007 6.794.007 9.375 0l4.692-.012-3.879-3.487c-2.133-1.917-3.896-3.486-3.917-3.486-.022 0-.248.172-.503.384-.632.523-.756.59-1.079.59-.161 0-.326-.032-.425-.083-.088-.046-.383-.27-.655-.499a7.156 7.156 0 0 0-.518-.413c-.012 0-1.767 1.574-3.899 3.498Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: flex;
}
</style>
