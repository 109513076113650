<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7.3a1 1 0 00-1.5 0L7 5.5 1.8.3A1 1 0 10.3 1.8L5.5 7 .3 12.2a1 1 0 101.5 1.5L7 8.5l5.2 5.2a1 1 0 101.5-1.5L8.5 7l5.2-5.2c.4-.4.4-1 0-1.5z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
