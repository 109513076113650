import { App } from 'vue';

export const clickOutside = {
  install(app: App) {
    app.directive('click-outside', {
      beforeMount: (el, binding) => {
        el.clickOutsideEvent = (event: Event) => {
          const isTarget = el === event.target;
          const containsTarget = el.contains(event.target);
          if (!isTarget && !containsTarget) {
            binding.value();
          }
        };
        document.addEventListener('click', el.clickOutsideEvent);
      },
      unmounted: (el) => {
        document.removeEventListener('click', el.clickOutsideEvent);
      },
    });
  },
};
