<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      fill="#B0B0B0"
      fill-rule="evenodd"
      d="M8.883 2.062c-.491.16-.961.553-1.168.975-.18.369-.227.596-.227 1.126v.5H4.8c-1.477 0-2.686.013-2.686.028 0 .052 2.365 7.418 2.434 7.583a.787.787 0 0 0 .228.278l.158.113h3.831l.018-.413c.02-.475.098-.662.338-.808.145-.088.21-.09 2.878-.09s2.732.002 2.878.09c.24.146.318.333.338.808l.018.413h3.831l.158-.113a.784.784 0 0 0 .228-.278c.07-.165 2.434-7.53 2.434-7.583 0-.015-1.209-.027-2.686-.027h-2.686v-.5c-.001-.53-.047-.758-.228-1.127-.137-.28-.508-.664-.778-.805-.457-.24-.384-.235-3.528-.23-2.242.002-2.963.016-3.096.06Zm5.995 1.38c.24.146.318.333.338.808l.018.414H8.777V4.34c0-.384.056-.615.184-.768.201-.24.214-.241 3.104-.23 2.593.009 2.668.012 2.813.1ZM1.001 12.889c-.003 8.048-.016 7.583.224 8.072.137.28.508.664.778.805.476.25-.161.235 9.997.235s9.521.015 9.997-.235c.27-.141.641-.525.778-.805.24-.489.227-.024.224-8.072l-.003-7.447-1.175 3.633c-.646 1.998-1.23 3.738-1.297 3.866a2.474 2.474 0 0 1-.345.465c-.284.295-.68.493-1.095.549-.173.023-1.11.043-2.083.044L15.234 14l-.018.413c-.02.475-.098.662-.338.808-.146.088-.21.09-2.878.09s-2.733-.002-2.878-.09c-.24-.146-.318-.333-.338-.808L8.766 14 7 13.998c-.973-.001-1.91-.02-2.083-.044a1.884 1.884 0 0 1-1.095-.55 2.472 2.472 0 0 1-.345-.464c-.067-.128-.65-1.868-1.297-3.866L1.004 5.441 1 12.888Zm9.065.444V14h3.868v-1.334h-3.868v.667Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
