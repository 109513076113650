import axios from 'axios';
import { GLOBAL_ROUTER } from '@/app/router/router';
import { LoginPage } from '@/pages/login/login.page';
import { SignupPage } from '@/pages/signup/signup.page';

if (!process.env.VUE_APP_BACKEND_HOST) {
  throw new Error('VUE_APP_BACKEND_HOST not set');
}

export const request = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_HOST,
});

request.interceptors.response.use(
  (res) => res,
  async (err) => {
    const isUnauthorized = err.response.status === 401;
    if (isUnauthorized) {
      await GLOBAL_ROUTER.isReady();
      const inAuthPage = [LoginPage.name, SignupPage.name].includes(
        GLOBAL_ROUTER.currentRoute.value.name as string,
      );
      if (!inAuthPage) {
        GLOBAL_ROUTER.replace({ name: LoginPage.name });
      }
    }

    return Promise.reject(err.response);
  },
);

export function setAuthInterceptor(session: string) {
  request.defaults.headers.Authorization = session;
}
